/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Button,
  useColorModeValue,
  Menu, 
  MenuButton, 
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV, FaCheckCircle } from "react-icons/fa";
import { TbXboxXFilled } from "react-icons/tb";

export const TYPES_OF_TASKS = {
  AUTOMATIC_APPROVE: 'automatic_approve',
  MANUAL_APPROVE: 'manual_approve'
}


function DashboardTableRow(props) {
  const { 
    description,
    isApprovedByAdmin, 
    isCompleted, 
    reward, 
    type, 
    linkWithResultFromUser,
    lastItem,
    onOptionClick,
    telegramId,
    email,
    userId
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr position="relative">
      <Td
        minWidth={{ sm: "200px" }}
        maxWidth={{ sm: '200px' }}
        ps='0px'
        border={lastItem ? "none" : null}
        borderBottomColor='#56577A'>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Flex direction='column'>
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                id: <strong>{ userId }</strong>
            </Text>
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                email: <strong>{ email }</strong>
            </Text>
            {
              telegramId && (
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                    telegramId: <strong>{ telegramId }</strong>
                </Text>
              )
            }
          </Flex>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        maxWidth={{ sm: '200px' }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text fontSize='sm' color='#fff' minWidth='100%'>
            {description}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text 
          fontSize='sm' 
          color='#fff' 
          fontWeight='bold' 
          pb='.5rem' 
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isCompleted ? (<FaCheckCircle size={25} color="green" />) : (<TbXboxXFilled size={30} color="red" />)}
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text 
          fontSize='sm' 
          color='#fff' 
          fontWeight='bold' 
          pb='.5rem' 
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isApprovedByAdmin ? (<FaCheckCircle size={25} color="green" />) : (<TbXboxXFilled size={30} color="red" />)}
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text textAlign="center" fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {
            reward ? `${reward?.quantity} (${reward?.currency})` : '-'
          }
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null} maxWidth={{ sm: '200px' }}>
        <Text textAlign="center" fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {
            linkWithResultFromUser ? (
              <a href={linkWithResultFromUser}>{linkWithResultFromUser}</a>
            ) : (
              '-'
            )
          }
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {type === TYPES_OF_TASKS.AUTOMATIC_APPROVE ? 'авто' : 'ручной'}
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? 'none' : null} textAlign="center">
        {type === TYPES_OF_TASKS.AUTOMATIC_APPROVE ? '-' : (
          <Menu>
          <MenuButton
            as={Button}
            p='0px'
            bg='transparent'
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
          >
            <Icon as={FaEllipsisV} color='gray.400' cursor='pointer' />
          </MenuButton>
          <MenuList
            bg="gray.700" 
            color="white" 
            border="1px"
            borderColor="gray.600"
            borderRadius="md"
          >
            <MenuItem
              onClick={onOptionClick}
              bg="gray.800" 
              _hover={{ bg: 'gray.600' }}
            >
              Подтвердить
            </MenuItem>
          </MenuList>
        </Menu>
        )}
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;
