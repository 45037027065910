import { Box, Heading, Text, Button, Icon, ChakraProvider } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle } from 'react-icons/fa';

import { getComingSoonLandingRoute } from 'utils/consts';
import { useLanguage } from 'utils/hooks';

const NotFound = () => {
    const currLang = useLanguage();
    const { t } = useTranslation();

    return (
        <ChakraProvider>
            <Box
            textAlign="center"
            py={10}
            px={6}
            minH="100vh"
            bgGradient="linear(to-r, teal.400, purple.500)"
            color="white"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            >
            <Icon as={FaExclamationTriangle} boxSize={16} mb={4} />
            <Heading as="h1" fontSize="6xl" mb={4}>
                404
            </Heading>
            <Text fontSize="2xl" mb={6}>
                {t('notFound.title')}
            </Text>
            <Link to={getComingSoonLandingRoute(currLang)}>
                <Button 
                colorScheme="teal" 
                bg="teal.400" 
                px={6} 
                _hover={{ bg: 'teal.500' }}
                >
                    {t('notFound.btn.backToLanding')}
                </Button>
            </Link>
            </Box>
        </ChakraProvider>
    );
};

export default NotFound;
