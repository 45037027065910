import React, { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Box, IconButton } from "@chakra-ui/react";
import { GB, RU, JP, KZ, UA } from 'country-flag-icons/react/3x2';
import { useLocation, useNavigate } from 'react-router-dom';

import { LANGUAGES } from 'utils/consts';
import { useLanguage } from "utils/hooks";

const LangSwitcher = () => {
    const currLang = useLanguage();
    const [selectedLang, setSelectedLang] = useState(currLang);
    const location = useLocation();
    const navigate = useNavigate();

    const handleLangChange = (lang) => {
        const currentRoute = `/${lang}${location?.pathname.slice(3)}`
        console.log('currentRoute ', currentRoute)
        setSelectedLang(lang);
        navigate(currentRoute, { replace: true })
    };

    const flags = {
        ru: <RU title="Русский" width="20px"/>,
        en: <GB title="English" width="20px"/>,
        jp: <JP title="日本語" width="20px"/> ,
        kz: <KZ title="Казахский" width="20px"/> ,
        ua: <UA title="Українська" width="20px"/> ,
    };

    return (
        <Box ms="auto">
            <Menu>
                <MenuButton as={IconButton} icon={flags[selectedLang]} variant="outline" _hover={{ bg: "transparent" }} border="none"/>
                <MenuList bg="gray.700" minW="auto" width="40px" border="none">
                    <MenuItem onClick={() => handleLangChange(LANGUAGES.RU)} bg="gray.700" _hover={{ bg: "gray.600" }} width="40px">
                        <RU title="Русский" width="20px" />
                    </MenuItem>
                    <MenuItem onClick={() => handleLangChange(LANGUAGES.UA)} bg="gray.700" _hover={{ bg: "gray.600" }} width="40px">
                        <UA title="Українська" width="20px" />
                    </MenuItem>
                    <MenuItem onClick={() => handleLangChange(LANGUAGES.KZ)} bg="gray.700" _hover={{ bg: "gray.600" }} width="40px">
                        <KZ title="Казахский" width="20px" />
                    </MenuItem>
                    <MenuItem onClick={() => handleLangChange(LANGUAGES.EN)} bg="gray.700" _hover={{ bg: "gray.600" }} width="40px">
                        <GB title="English" width="20px" />
                    </MenuItem>
                    <MenuItem onClick={() => handleLangChange(LANGUAGES.JP)} bg="gray.700" _hover={{ bg: "gray.600" }} width="40px">
                        <JP title="日本語" width="20px" />
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export default LangSwitcher;
