import React from 'react'

const PreloaderLanding = () => {

    return (
        <>
            <style>
                {`
                @-webkit-keyframes dreamrotate {
                    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
                    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
                }
                @keyframes dreamrotate {
                    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
                    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
                }
                `}
            </style>
            <div
                style={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    zIndex: 99999,
                    top: 0,
                    left: 0,
                    background: 'linear-gradient(to right, #4834d4, #341f97)',
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-35px",
                        marginLeft: "-35px",
                        zIndex: 100
                    }}
                >
                    <div
                        id="dream-load"
                        style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            borderWidth: "3px",
                            borderStyle: "solid",
                            borderColor: "transparent",
                            borderTopColor: "rgba(255, 255, 255, 0.5)",
                            WebkitAnimation: "dreamrotate 2400ms linear infinite",
                            animation: "dreamrotate 2400ms linear infinite",
                            zIndex: 999,
                            position: "relative"
                        }}
                    >
                        <div
                            style={{
                                content: '""',
                                position: "absolute",
                                top: "7.5px",
                                left: "7.5px",
                                right: "7.5px",
                                bottom: "7.5px",
                                borderRadius: "50%",
                                borderWidth: "3px",
                                borderStyle: "solid",
                                borderColor: "transparent",
                                borderTopColor: "rgba(255, 255, 255, 0.5)",
                                WebkitAnimation: "dreamrotate 2700ms linear infinite",
                                animation: "dreamrotate 2700ms linear infinite"
                            }}
                        />
                        <div
                            style={{
                                content: '""',
                                position: "absolute",
                                top: "20px",
                                left: "20px",
                                right: "20px",
                                bottom: "20px",
                                borderRadius: "50%",
                                borderWidth: "3px",
                                borderStyle: "solid",
                                borderColor: "transparent",
                                borderTopColor: "rgba(255, 255, 255, 0.5)",
                                WebkitAnimation: "dreamrotate 1800ms linear infinite",
                                animation: "dreamrotate 1800ms linear infinite"
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreloaderLanding
