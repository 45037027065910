import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

import { taskService } from 'services/task.service';

class TaskStore {
    isLoading = false;
    errors = undefined;
    userAndTaskData = null;

    tasks = null;
    isLoadingTasks = false;

    resultWithApprovedTask = null;
    resultWithDailyCompletedTask = null;
    isLoadingResultWithDailyCompletedTask = false;

    resultTaskTypesWithCompletedCounts = null;
    isLoadingResultTaskTypesWithCompletedCounts = false;

    resultTasksThatNeedApprove = null;
    isLoadingResultTasksThatNeedApprove = false;

    resultAllCompletedTaskForUser = null;
    isLoadingAllCompletedTaskForUser = null;


    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    async getUserAndTask(userId) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            const response = await taskService.getUserAndTasks(userId);
            this.userAndTaskData = response?.data
            this.tasks = response?.data?.tasks
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async getTasksForUser(userId) {
        this.isLoadingTasks = true;
        this.errors = undefined;

        try {
            const response = await taskService.getTasks(userId);
            this.tasks = response.data
            this.isLoadingTasks = false;
        } catch (error) {
            this.isLoadingTasks = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async setTaskAsApproved(taskId) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            const response = await taskService.setTaskAsApprovedByAdmin(taskId);
            this.resultWithApprovedTask = response.data
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async setTaskAsCompleted(taskId, linkWithResultFromUser) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            const response = await taskService.setTaskAsCompleted(taskId, linkWithResultFromUser);
            this.resultWithApprovedTask = response.data
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async setDailyTaskAsCompleted(taskId) {
        this.isLoadingResultWithDailyCompletedTask = true;
        this.errors = undefined;

        try {
            const response = await taskService.setDailyTaskAsCompleted(taskId);
            this.resultWithDailyCompletedTask = response.data;
            this.isLoadingResultWithDailyCompletedTask = false;
        } catch (error) {
            this.isLoadingResultWithDailyCompletedTask = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async getTaskTypesWithCompletedCounts() {
        this.isLoadingResultTaskTypesWithCompletedCounts = true;
        this.errors = undefined;

        try {
            const response = await taskService.getTaskTypesWithCompletedCounts();
            this.resultTaskTypesWithCompletedCounts = response.data;
            this.isLoadingResultTaskTypesWithCompletedCounts = false;
        } catch (error) {
            this.isLoadingResultTaskTypesWithCompletedCounts = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async getTasksThatNeedApprove(
        search = '',
        page = 1,
        limit = 10,
    ) {
        this.isLoadingResultTasksThatNeedApprove = true;
        this.errors = undefined;

        try {
            const response = await taskService.getTasksThatNeedApprove(
                search,
                page,
                limit,
            );
            this.resultTasksThatNeedApprove = response.data;
            this.isLoadingResultTasksThatNeedApprove = false;

            return response.data
        } catch (error) {
            this.isLoadingResultTasksThatNeedApprove = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async getAllCompletedTaskForUser(userId) {
        this.isLoadingAllCompletedTaskForUser = true;
        this.errors = undefined;

        try {
            const response = await taskService.getAllCompletedTaskForUser(userId);
            this.resultAllCompletedTaskForUser = response.data;
            this.isLoadingAllCompletedTaskForUser = false;
        } catch (error) {
            this.isLoadingAllCompletedTaskForUser = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }
}

export const TaskStoreInstance = new TaskStore();
export const TaskStoreInstanceCTX = createContext(TaskStoreInstance);
