import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { ALLOWED_LANGUAGES } from 'utils/consts';

i18n
  .use(Backend) // Load translations using HTTP backend
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    keySeparator: false,
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator'], // First check URL path, then querystring
      lookupFromPathIndex: 0,
      lookupQuerystring: 'lang',
    },
    debug: true,
    interpolation: {
      escapeValue: false, // React already handles XSS
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

  
const handleLanguageDetection = () => {
  const originalDetector = i18n.services.languageDetector.detect;
  i18n.services.languageDetector.detect = (options) => {
    if (i18n.language === i18n.options.fallbackLng) {
      return [i18n.options.fallbackLng];
    }
    return originalDetector(options);
  };
};

i18n.on('languageChanged', (lng) => {
  if (!ALLOWED_LANGUAGES.includes(lng)) {
    console.warn(`Language "${lng}" is not allowed. Falling back to default language.`);
    i18n.changeLanguage(i18n.options.fallbackLng);
  } else if (lng === i18n.options.fallbackLng) {
    handleLanguageDetection();
  }
});

export default i18n;
