import { axiosWithAuth } from "../api/interseptors";

export const taskService = {
  async getUserAndTasks(userId) {
    const response = await axiosWithAuth.get(`/api/tasks/user-and-tasks/${userId}/`);

    return response;
  },

  async getTasks(userId) {
    const response = await axiosWithAuth.get(`/api/tasks/user/${userId}/`);

    return response;
  },

  async setTaskAsApprovedByAdmin(taskId) {
    const response = await axiosWithAuth.put(`/api/tasks/${taskId}/approve-by-admin/`);

    return response;
  },

  async setTaskAsCompleted(taskId, linkWithResultFromUser) {
    const response = await axiosWithAuth.put(`/api/tasks/${taskId}/complete/`, { linkWithResultFromUser });

    return response;
  },

  async setDailyTaskAsCompleted(taskId) {
    const response = await axiosWithAuth.put(`/api/tasks/${taskId}/complete-daily-task/`);

    return response;
  },

  async getTaskTypesWithCompletedCounts() {
    const response = await axiosWithAuth.get(`/api/tasks/types-with-completed/`);

    return response;
  },

  async getTasksThatNeedApprove(
    search = '',
    page = 1,
    limit = 10,
  ) {
    const response = await axiosWithAuth.get(`/api/tasks/need-approve?page=${page}&limit=${limit}&search=${search}`);

    return response;
  },

  async getAllCompletedTaskForUser(userId) {
    const response = await axiosWithAuth.get(`/api/tasks/user/${userId}/completed/`);

    return response;
  },
};