import React, { useContext, useEffect, Suspense, lazy } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { authRouter, publicRouter, authRouterOnlyForLandingRelease } from "../../routers";
import adminRoutes from "routesAdminPanel.js";
import { 
  COMING_SOON_LANDING_ROUTE, 
  ADMIN_PANEL_ROUTE,
  NOT_FOUND_ROUTE,
  getComingSoonLandingRoute,
  getLoginRoute,
  getRegisterRoute,
  getNotFoundRoute,
  getAdminPanelRoute,
} from "../../utils/consts";
import { useLanguage } from "utils/hooks";

import { UserStoreInstanceCTX } from "../../stores/userStore";

import { stringToBoolean } from "utils/utils";
import { getAdminRoutes } from "./utils";

import PlatformLayout from "layouts/Platform";
import NotFound from 'pages/NotFound';
import LanguageWrapper from 'components/LanguageWrapper';
import PreloaderLanding from 'components/PreloaderLanding'

const AdminLayout = lazy(() => import('layouts/Admin'));
const LandingLayout = lazy(() => import('layouts/Landing'));

const Loader = () => <div>Loading...</div>;

const AppRouter = observer(() => {
  const { isUserAuthorized, isUserAdmin } = useContext(UserStoreInstanceCTX);
  const location = useLocation();
  const navigateTo = useNavigate();
  const currLang = useLanguage();

  const currentAuthRouter = stringToBoolean(process.env.REACT_APP_IS_LANDING_PRE_RELEASE) ? authRouterOnlyForLandingRelease : authRouter

  useEffect(() => {
    if (window) {
      const isLoginOrRegisterPath = location?.pathname === getLoginRoute(currLang) || location?.pathname === getRegisterRoute(currLang);

      if (isUserAuthorized && isUserAdmin && isLoginOrRegisterPath) {
        navigateTo(getAdminPanelRoute(currLang));
      } else if (isUserAuthorized && isLoginOrRegisterPath) {
        navigateTo(getComingSoonLandingRoute(currLang));
      }
    }
  }, [isUserAuthorized, isUserAdmin, location]);

  return (
    <Routes>
      {
        isUserAdmin && (
          <Route 
            path={`/:lang${ADMIN_PANEL_ROUTE}`} 
            element={
              <LanguageWrapper>
                <Suspense fallback={<Loader />}>
                  <AdminLayout />
                </Suspense>
              </LanguageWrapper>
            }
          >
              { getAdminRoutes(adminRoutes(currLang), currLang) }
          </Route>
        )
      }
      <Route 
        path={`/:lang${COMING_SOON_LANDING_ROUTE}`} 
        element={
          <LanguageWrapper>
            <Suspense fallback={<PreloaderLanding />}>
              <LandingLayout />
            </Suspense>
          </LanguageWrapper>
        } 
      />

      {currentAuthRouter.map(({ path, Component }) =>
        <Route 
          key={path} 
          path={`/:lang/${path.replace(/^\//, '')}`} 
          element={
            <LanguageWrapper>
              <PlatformLayout>
                {
                  isUserAuthorized ? (
                    Component
                  ) : (
                    <Navigate to={getLoginRoute(currLang)} replace={true} />
                  )
                }
              </PlatformLayout>
            </LanguageWrapper>
          } 
        />
      )}
      {publicRouter.map(({ path, Component }) =>
        <Route 
          key={path} 
          path={`/:lang/${path.replace(/^\//, '')}`} 
          element={
            <LanguageWrapper>
              <PlatformLayout>
                { Component }
              </PlatformLayout>
            </LanguageWrapper>
          } 
        />
      )}

      <Route path={`/:lang${NOT_FOUND_ROUTE}`} element={<NotFound />} />

      <Route
        path="*"
        element={<Navigate to={getNotFoundRoute(currLang)} replace={true} />}
      />
    </Routes>
  )
});

export default AppRouter;