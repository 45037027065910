import React from 'react'
import { ChakraProvider, Box, Flex } from "@chakra-ui/react";

import NavBar from "components/NavBar";
import NavBarTempForLadingRelease from 'components/NavBarTempForLadingRelease';

import { stringToBoolean } from 'utils/utils';

const PlatformLayout = ({ children }) => {

    return (
        <ChakraProvider>
              <Flex direction="column" bg="gray.900" color="white" minHeight="100vh" height="100%">
                {
                  stringToBoolean(process.env.REACT_APP_IS_LANDING_PRE_RELEASE) ? (
                    <NavBarTempForLadingRelease />
                  ) : (
                    <NavBar />
                  )
                }
                <Flex direction="column" alignItems="center" flex="1" padding="20px" height="100%">
                  <Box mt={8} mb={8} textAlign="center" maxWidth={1500} height="100%">
                    { children }
                  </Box>
                </Flex>
              </Flex>
        </ChakraProvider>
    )
}

export default PlatformLayout;
