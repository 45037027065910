import { axiosWithAuth } from "../api/interseptors";
import { saveUserId } from "../utils/cookies";

export const userService = {
  async getUserId() {
    const response = await axiosWithAuth.get(`/api/auth/me/`);
    const id = response.data.id || null;

    if (id) saveUserId(id);

    return id ;
  },

  async getUser(userId) {
    const response = await axiosWithAuth.get(`/api/users/${userId}/`);

    return response;
  }
};