import React from 'react';
import { Route } from "react-router-dom";

const removeFirstThreeCharsRegex = (path) => {
  return path.replace(/^.{3}/, '/:lang');
}

export const getAdminRoutes = (routes, currLang) => {
    return routes?.map((prop, key) => {
      if (prop.collapse) {
        return getAdminRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getAdminRoutes(prop.views);
      }
      if (prop.layout === `/${currLang}/admin`) {
        if(!prop.component) return;
        
        return (
          <Route
            path={removeFirstThreeCharsRegex(prop.layout) + prop.path}
            element={React.createElement(prop.component)}
            key={key}
          />
        );
      } else {
        return null
      }
    });
};