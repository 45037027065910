import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();

  const language = lang || i18n.language;

  return language;
};
