import React from 'react'
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { observer } from "mobx-react-lite";
import { I18nextProvider } from 'react-i18next';

import AppRouter from "./components/AppRouter/AppRouter";

import i18n from './i18n';

const App = observer(() => {
  return (
    <I18nextProvider i18n={i18n}>
      <HelmetProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </HelmetProvider>
    </I18nextProvider>
  );
});

export default App;
