import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams, Navigate } from 'react-router-dom';

import { NOT_FOUND_ROUTE, ALLOWED_LANGUAGES, LANGUAGES } from 'utils/consts';

const LanguageWrapper = ({ children }) => {
    const { i18n } = useTranslation();
    const { lang } = useParams();
    const [defaultLang, setDefaultLang] = useState(LANGUAGES.RU)
  
    useEffect(() => {
        if (ALLOWED_LANGUAGES.includes(lang)) {
            if (lang) {
                setDefaultLang(lang);
                i18n.changeLanguage(lang);
            }
        }
    }, [lang, i18n]);

    if (!ALLOWED_LANGUAGES.includes(defaultLang)) {
        return <Navigate to={NOT_FOUND_ROUTE} replace={true} />;
    }
  
    return <>{children}</>;
}

export default LanguageWrapper;
