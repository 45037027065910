import React, { useContext, useState, useEffect } from "react";
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";


// Custom components
import Card from "components/adminPanelComponents/Card/Card.js";
import CardHeader from "components/adminPanelComponents/Card/CardHeader.js";
import CardBody from "components/adminPanelComponents/Card/CardBody.js";
import Pagination from 'components/adminPanelComponents/Pagination';

import CardTypeOfTask from 'components/adminPanelComponents/Card/CardTypeOfTask';

// Table Components
import TablesProjectRow from "components/adminPanelComponents/Tables/TablesProjectRow";

import { TaskStoreInstanceCTX } from "stores/taskStore";

const Tasks = observer(() => {
  const { 
    isLoading, 
    setTaskAsApproved,

    getTaskTypesWithCompletedCounts,
    resultTaskTypesWithCompletedCounts,
    isLoadingResultTaskTypesWithCompletedCounts,

    getTasksThatNeedApprove,
    resultTasksThatNeedApprove,
    isLoadingResultTasksThatNeedApprove,

  } = useContext(TaskStoreInstanceCTX);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [taskTypeByTitle, setTaskTypeByTitle] = useState();
  const [activeTab, setActiveTab] = useState('')


  useEffect(() => {
    getTaskTypesWithCompletedCounts()
  }, [])

  const handleCardTypeOfTaskClick = async (taskTitle) => {
    setTaskTypeByTitle(taskTitle)
    setActiveTab(taskTitle)
    const data = await getTasksThatNeedApprove(taskTitle, 1, pageLimit)
    setCurrentPage(Number(data?.currentPage))
  };

  const handlePageChange = async (currentPageValue) => {
    setCurrentPage(currentPageValue)
    await getTasksThatNeedApprove(taskTypeByTitle, currentPageValue, pageLimit)
  }

  const handleApproveClick = async (taskId, taskTitle) => {
    console.log('currentPage ', currentPage)
    await setTaskAsApproved(taskId)
    if(resultTasksThatNeedApprove?.tasks.length === 1) {
        await getTasksThatNeedApprove(taskTitle, currentPage - 1, pageLimit)
    } else {
        await getTasksThatNeedApprove(taskTitle, currentPage, pageLimit)
    }
    await getTaskTypesWithCompletedCounts() 
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
        {isLoadingResultTaskTypesWithCompletedCounts ? (
            <Flex justify="center" align="center" height="200px">
                <Spinner size="xl" color="#fff" />
            </Flex>
        ) : (
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 6 }} spacing={4}>
                {resultTaskTypesWithCompletedCounts?.map((task) => (
                    <CardTypeOfTask
                        key={task.title}
                        active={activeTab === task.title}
                        title={task.title}
                        totalTasks={task.totalTasks}
                        completedTasks={task.completedTasks}
                        approvedTasksByAdmin={task.approvedTasksByAdmin}
                        onClick={() => handleCardTypeOfTaskClick(task?.title)}
                    />
                ))}
            </SimpleGrid>
        )}

      {
        resultTasksThatNeedApprove && resultTasksThatNeedApprove?.tasks && (
            <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px' position="relative">
                {isLoading && (
                    <Flex
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        justifyContent="center"
                        alignItems="center"
                        background="rgba(255, 255, 255, 0.3)"
                        zIndex="1"
                    >
                    <Spinner size="lg" color="white" />
                    </Flex>
                )}
                <CardHeader p='6px 0px 22px 0px'>
                    <Flex direction='column'>
                        <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
                            {
                                resultTasksThatNeedApprove?.tasks.length ? (
                                    'Список Заданий для подтверждение'
                                ) : (
                                    'Заданий для подтверждение не найдено'
                                )
                            }
                        </Text>
                    </Flex>
                </CardHeader>
                {
                    resultTasksThatNeedApprove?.tasks.length ? (
                        <CardBody>
                            <VStack minWidth="100%">
                                <Table variant='simple' color='#fff' minWidth="100%">
                                    <Thead>
                                        <Tr my='.8rem' ps='0px'>
                                            <Th
                                            ps='0px'
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Пользователь
                                            </Th>
                                            <Th
                                            ps='0px'
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Описание
                                            </Th>
                                            <Th
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Выполенно
                                            </Th>
                                            <Th
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Подтверджено АДМИНОМ
                                            </Th>
                                            <Th
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Награда
                                            </Th>
                                            <Th
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Дополнение
                                            </Th>
                                            <Th
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Тип
                                            </Th>
                                            <Th borderBottomColor='#56577A'></Th>
                                        </Tr>
                                        </Thead>
                                        <Tbody>
                                            { resultTasksThatNeedApprove?.tasks?.map((task, index, arr) => {
                                                return (
                                                <TablesProjectRow
                                                    key={task?._id}
                                                    telegramId={task?.userId?.telegramId}
                                                    email={task?.userId?.email}
                                                    userId={task?.userId?.id}
                                                    description={task?.description}
                                                    totalSteps={task?.totalSteps}
                                                    currentSteps={task?.currentSteps}
                                                    isApprovedByAdmin={task?.isApprovedByAdmin}
                                                    isCompleted={task?.isCompleted}
                                                    reward={task?.reward}
                                                    type={task?.type}
                                                    linkWithResultFromUser={task?.linkWithResultFromUser}
                                                    onOptionClick={() => handleApproveClick(task?._id, task?.title)}
                                                    lastItem={index === arr.length - 1 ? true : false}
                                                />
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                    {
                                        resultTasksThatNeedApprove?.tasks.length && resultTasksThatNeedApprove?.totalPages > 1 && (
                                            <Flex justify="center" align="center" mt={4} mb={4}>
                                                <Pagination 
                                                    currentPage={currentPage} 
                                                    totalPages={resultTasksThatNeedApprove?.totalPages} 
                                                    onPageChange={handlePageChange} 
                                                />
                                            </Flex>
                                        )
                                    }
                            </VStack>
                        </CardBody>
                    ) : ''
                }
            </Card>
        )
      }
    </Flex>
  );
})

export default Tasks;
