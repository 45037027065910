import React, { Suspense, lazy } from 'react';

import {
  HOME_ROUTE,
  COMING_SOON_ROUTE,
  TASKS_ROUTE,
  GAMES_ROUTE,
  BLAST_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  RUSSIAN_ROULETTE_ROUTE,
  REFERRAL_LINK_ROUTE,
  PROFILE_ROUTE,
} from './utils/consts';

const Login = lazy(() => import('pages/Auth/Login'));
const Register = lazy(() => import('pages/Auth/Register'));
const Profile = lazy(() => import('pages/Profile'));
const ReferralLink = lazy(() => import('pages/ReferralLink/ReferralLink'));
const Home = lazy(() => import('pages/Home'));
const BlastGame = lazy(() => import('pages/Games/Blast'));
const RussianRoulette = lazy(() => import('pages/Games/RussianRoulette'));

const Loader = () => <div>Loading...</div>;

export const authRouter = [
  { path: HOME_ROUTE, Component: <Suspense fallback={<Loader />}><Home /></Suspense> },
  { path: TASKS_ROUTE, Component: <Suspense fallback={<Loader />}><Home /></Suspense> },
  { path: GAMES_ROUTE, Component: <Suspense fallback={<Loader />}><Home /></Suspense> },
  { path: BLAST_ROUTE, Component: <Suspense fallback={<Loader />}><BlastGame /></Suspense> },
  { path: RUSSIAN_ROULETTE_ROUTE, Component: <Suspense fallback={<Loader />}><RussianRoulette /></Suspense> },
  { path: REFERRAL_LINK_ROUTE, Component: <Suspense fallback={<Loader />}><ReferralLink /></Suspense> },
  { path: PROFILE_ROUTE, Component: <Suspense fallback={<Loader />}><Profile /></Suspense> },
];

export const authRouterOnlyForLandingRelease = [
  { path: REFERRAL_LINK_ROUTE, Component: <Suspense fallback={<Loader />}><ReferralLink /></Suspense> },
  { path: PROFILE_ROUTE, Component: <Suspense fallback={<Loader />}><Profile /></Suspense> }
];

export const publicRouter = [
  { path: LOGIN_ROUTE, Component: <Suspense fallback={<Loader />}><Login /></Suspense> },
  { path: REGISTER_ROUTE, Component: <Suspense fallback={<Loader />}><Register /></Suspense> },
];