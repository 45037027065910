export const HOME_ROUTE = '/';
export const GAMES_ROUTE = '/games';
export const COMING_SOON_ROUTE = '/coming-soon';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const TASKS_ROUTE = '/tasks';
export const SHOP_ROUTE = '/shop';
export const PROFILE_ROUTE = '/profile';
export const BLAST_ROUTE = '/blast';
export const RUSSIAN_ROULETTE_ROUTE = '/russian-roulette';
export const REFERRAL_LINK_ROUTE = '/referral-link';

export const COMING_SOON_LANDING_ROUTE = '/coming-soon-landing';

export const ADMIN_PANEL_ROUTE = '/admin';

export const NOT_FOUND_ROUTE = '/404';


export const LANGUAGES = {
    RU: 'ru',
    EN: 'en',
    JP: 'jp',
    KZ: 'kz',
    UA: 'ua',
}

export const ALLOWED_LANGUAGES = ['en', 'ru', 'jp', 'ua', 'kz'];


export const getHomeRoute = (lang = LANGUAGES.RU) => `/${lang}`;
export const getGamesRoute = (lang = LANGUAGES.RU) => `/${lang}${GAMES_ROUTE}`;
export const getComingSoonRoute = (lang) => `/${lang}/coming-soon`;

export const getLoginRoute = (lang = LANGUAGES.RU) => `/${lang}${LOGIN_ROUTE}`;
export const getRegisterRoute = (lang = LANGUAGES.RU) => `/${lang}${REGISTER_ROUTE}`;

export const getTasksRoute = (lang = LANGUAGES.RU) => `/${lang}${TASKS_ROUTE}`;
export const getShopRoute = (lang = LANGUAGES.RU) => `/${lang}${SHOP_ROUTE}`;
export const getProfileRoute = (lang = LANGUAGES.RU) => `/${lang}${PROFILE_ROUTE}`;
export const getBlastRoute = (lang) => `/${lang}/blast`;
export const getRussianRouletteRoute = (lang) => `/${lang}/russian-roulette`;
export const getReferralLinkRoute = (lang = LANGUAGES.RU) => `/${lang}${REFERRAL_LINK_ROUTE}`;
export const getComingSoonLandingRoute = (lang = LANGUAGES.RU) => `/${lang}${COMING_SOON_LANDING_ROUTE}`;
export const getAdminPanelRoute = (lang = LANGUAGES.RU) => `/${lang}${ADMIN_PANEL_ROUTE}`;
export const getNotFoundRoute = (lang = LANGUAGES.RU) => `/${lang}${NOT_FOUND_ROUTE}`;
