import React from 'react';
import {
    Text,
    Card,
    CardHeader,
    CardBody,
    VStack,
    Divider,
  } from "@chakra-ui/react";

const CardTypeOfTask = ({ title, totalTasks, completedTasks, approvedTasksByAdmin, onClick, active }) => {
    return (
      <Card
        borderRadius="15px"
        bg={`${active ? 'rgb(91 91 91 / 65%)': 'rgba(34, 34, 34, 0.7)'}`}
        color="#fff"
        cursor="pointer"
        onClick={onClick}
        _hover={{ boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)" }}
      >
        <CardHeader>
            <Text fontSize="lg" fontWeight="bold" mb="20px">
            {title}
            </Text>
        </CardHeader>
        <CardBody>
            <VStack align="start" spacing={2}>
              <Text>Всего задач: {totalTasks}</Text>
              <Divider />
              <Text>Завершенные: {completedTasks}</Text>
              <Divider />
              <Text>Подтверждено админом: {approvedTasksByAdmin}</Text>
              <Divider />
              <Text>Нужно подтвердить: {completedTasks - approvedTasksByAdmin}</Text>
            </VStack>
        </CardBody>
      </Card>
    );
};

export default CardTypeOfTask
