import React from 'react';
import { Button, HStack, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPaginationItems = () => {
    const items = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        items.push(i);
      }
    } else {
      items.push(1);
      
      if (currentPage > 3) {
        items.push('...');
      }

      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);
      for (let i = start; i <= end; i++) {
        items.push(i);
      }
      if (currentPage < totalPages - 2) {
        items.push('...');
      }
      items.push(totalPages);
    }

    return items;
  };

  const paginationItems = getPaginationItems();

  return (
    <HStack spacing={2} mt={4} justifyContent="center">
      <IconButton
        icon={<ChevronLeftIcon />}
        isDisabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
        aria-label="Previous Page"
        background="#582cff"
      />

      {paginationItems.map((page, index) => (
        <Button
          key={index}
          background={page === currentPage ? '#582cff' : 'transparent'}
          onClick={() => page !== '...' && onPageChange(page)}
          variant={page === currentPage ? 'solid' : 'outline'}
          color="white"
        >
          {page}
        </Button>
      ))}

      <IconButton
        icon={<ChevronRightIcon />}
        isDisabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        aria-label="Next Page"
        background="#582cff"
      />
    </HStack>
  );
};

export default Pagination;
