import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Box, Flex, Button, Avatar, MenuItem, MenuList, MenuButton, Menu, Divider } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

import {
  getLoginRoute,
  getRegisterRoute,
  getProfileRoute,
  getReferralLinkRoute,
  getComingSoonLandingRoute,
  getAdminPanelRoute,
} from "../utils/consts";
import { useLanguage } from "utils/hooks";

import { UserStoreInstanceCTX } from '../stores/userStore';
import { AuthStoreInstanceCTX } from '../stores/authStore';

import LangSwitcher from './LangSwitcher';


const AvatarWithDropdown = ({ logoutHandler, userData, isUserAdmin, currLang }) => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton as={Button} variant="link" cursor="pointer">
        <Avatar name={userData?.username} src={userData?.photoUrl} />
      </MenuButton>
      <MenuList 
        bg="gray.700" 
        color="white" 
        border="1px"
        borderColor="gray.600"
        borderRadius="md"
        zIndex={5}
      >
        {
          isUserAdmin && (
            <MenuItem 
              bg="gray.800" 
              _hover={{ bg: 'gray.600' }} 
              mb={2}
              as={Link} 
              to={getAdminPanelRoute(currLang)}
            >
              {t('navbar.avatarDropdown.adminPanel')}
            </MenuItem>
          )
        }
        <MenuItem 
          bg="gray.800" 
          _hover={{ bg: 'gray.600' }} 
          mb={2}
          as={Link} 
          to={getProfileRoute(currLang)}
        >
          {t('navbar.avatarDropdown.profile')}
        </MenuItem>
        <MenuItem
          bg="gray.800" 
          _hover={{ bg: 'gray.600' }} 
          mb={2}
          as={Link} 
          to={getReferralLinkRoute(currLang)}
        >
          {t('navbar.avatarDropdown.refLink')}
        </MenuItem>
        <MenuItem
          bg="gray.800" 
          _hover={{ bg: 'gray.600' }} 
          onClick={logoutHandler}
        >
          {t('navbar.avatarDropdown.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}


const NavBarTempForLadingRelease = observer(() => {
  const { isUserAuthorized, userData, isUserAdmin } = useContext(UserStoreInstanceCTX);
  const { logout } = useContext(AuthStoreInstanceCTX);
  const { t } = useTranslation();
  const handleNavigate = useNavigate();
  const currLang = useLanguage();


  const logoutHandler = async () => {
    await logout();
    handleNavigate(getComingSoonLandingRoute(currLang));
    window?.location?.reload()
  }

  return (
    <Box bg="gray.800">
      <Flex as="nav" bg="gray.800" p={4} maxWidth="1024px" align="center" height="100px" margin="0 auto" maxHeight="72px" justifyContent="flex-end">
        <LangSwitcher />
        <Box
          height="100%"
          ml={2}
          mr={2}
        ></Box>
        {
          isUserAuthorized ? (
            <>

              <AvatarWithDropdown logoutHandler={logoutHandler} userData={userData} isUserAdmin={isUserAdmin} currLang={currLang}/>
            </>
          ) : (
            <>
              <Button as={Link} to={getLoginRoute(currLang)} ml={4} mr={4} bg="gray.700" _hover={{ bg: "gray.600" }} color="#fff">
                {t('navbar.btn.login')}
              </Button>
              
              <Box
                height="100%"
              >
                <Divider orientation="vertical" borderColor="gray.300" />
              </Box>
              <Button as={Link} to={getRegisterRoute(currLang)} ml={4} mr={4} bg="gray.700" _hover={{ bg: "gray.600" }} color="#fff">
                {t('navbar.btn.register')}
              </Button>
            </>
          )
        }
      </Flex>
    </Box>
  );
});

export default NavBarTempForLadingRelease;