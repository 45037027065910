import { axiosClassic } from "../api/interseptors";
import { 
  removeFromStorage, 
  saveAccessTokenStorage, 
  saveRefreshTokenStorage,
  saveUserId,
} from "../utils/cookies";

const URL_AUTH = '/api/auth'

export const authService = {
  async login(data) {
    const response = await axiosClassic.post(`${URL_AUTH}/login`, data);

    if (response?.data?.accessToken) saveAccessTokenStorage(response?.data?.accessToken);
    if (response?.data?.refreshToken) saveRefreshTokenStorage(response.data.refreshToken);
    if (response?.data?.id) saveUserId(response?.data?.id)

    return response;
  },

  async register(data, referralCode) {
    let registerUrl = `${URL_AUTH}/signup`
    if(referralCode) {
      registerUrl = `${URL_AUTH}/signup?referralCode=${referralCode}`
    }
    
    const response = await axiosClassic.post(registerUrl, data)

    if (response?.data?.accessToken) saveAccessTokenStorage(response?.data?.accessToken);
    if (response?.data?.refreshToken) saveRefreshTokenStorage(response.data.refreshToken);
    if (response?.data?.id) saveUserId(response?.data?.id)

    return response;
  },

  async telegramAuth(data, referralCode) {
    try {
      let telegramAuthUrl = `${URL_AUTH}/telegram`
      if(referralCode) {
        telegramAuthUrl = `${URL_AUTH}/telegram?referralCode=${referralCode}`
      }

      const response = await axiosClassic.post(telegramAuthUrl, data)
      
      if (response?.data?.accessToken) saveAccessTokenStorage(response?.data?.accessToken);
      if (response?.data?.id) saveUserId(response?.data?.id)

      return response

    } catch(error) {
      console.error(error)
    }
  },

  async logout() {
    removeFromStorage();
  }
};