import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

import { authService } from '../services/auth.services';

class AuthStore {
    isLoading = false;
    errors = undefined;
    values = {
        username: '',
        email: '',
        password: '',
    };

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setUsername(username) {
        this.values.username = username;
    }

    setEmail(email) {
        this.values.email = email;
    }

    setPassword(password) {
        this.values.password = password;
    }

    setError(errors) {
        this.values.errors = errors;
    }

    reset() {
        this.values.username = '';
        this.values.email = '';
        this.values.password = '';
    }

    async login() {
        this.isLoading = true;
        this.errors = undefined;

        try {
            await authService.login({
                email: this.values.email,
                password: this.values.password,
            });
            this.isLoading = false;
            this.reset();

        } catch (error) {
            this.isLoading = false;
            this.reset();
            this.errors = error.response;
            throw error;
        }
    }

    async register(referralCode) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            await authService.register({
                username: this.values.username,
                email: this.values.email,
                password: this.values.password,
            }, referralCode);
            this.isLoading = false;
            this.reset();

        } catch (error) {
            this.isLoading = false;
            this.reset();
            this.errors = error.response;
            throw error;
        }
    }

    async logout() {
        authService.logout();
    }
}

export const AuthStoreInstance = new AuthStore();
export const AuthStoreInstanceCTX = createContext(AuthStoreInstance);
